<template>
    <div>
    <b-modal 
        :visible="isShowCacelPopup"
        hide-footer
    >
        <template #modal-title>
           ยกเลิกใบเสร็จ
        </template>
        <div class="form-boder-input">
            <b-form-group>
                <label>รายการที่ยกเลิก</label>
                
                <b-form-checkbox-group
        id="checkbox-group-1"
        value-field="id"
      text-field="item_name"
      v-model="items_cancle"
        :options="receipt.items"
     
        name="flavour-1"
      ></b-form-checkbox-group>
            </b-form-group>
            
            <b-form-group
               label="กรุณาใส่สาเหตุที่ยกเลิก"
               label-for="note"
             >
             <b-form-textarea
             v-model="note"
          
            ></b-form-textarea>
 
              
             </b-form-group>
             <div class="d-flex mt-2">
             <b-button
               v-ripple.400="'rgba(255, 255, 255, 0.15)'"
               variant="primary"
               class="mr-2"
               v-if="note && items_cancle.length > 0"
               type="button"
               @click="CancelReceiptStatus"
             >
               ยืนยัน
             </b-button>
             <b-button
               v-ripple.400="'rgba(255, 255, 255, 0.15)'"
               variant="primary"
               class="mr-2"
               v-else
               disabled
               
             >
               ยืนยัน
             </b-button>
             <b-button
               v-ripple.400="'rgba(186, 191, 199, 0.15)'"
               type="button"
               variant="outline-secondary"
               @click="isShowCacelPopup=false"
             >
               Cancel
             </b-button>
           </div>
            
        </div>
        
    </b-modal>
    <b-modal
    :visible="isPrintFormActive"
        hide-footer
    >
        <template #modal-title>
           คุณต้องการ รายละเอียด voucher และคอร์สคงเหลือไหม?
        </template>
        <div class="demo-inline-spacing">
            <b-form-radio
                v-model="isShowVoucher"
                
                name="some-radios3"
                value="1"
                >
                แสดง
                </b-form-radio>
                <b-form-radio
                v-model="isShowVoucher"
                
                name="some-radios3"
                
                value="0"
                >
                ไม่แสดง
                </b-form-radio>
                
        </div>
        <div class="mt-2">
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="button"
                    @click="generateReport"
                    >
                    Print
                    </b-button>

                </div>
        
    </b-modal>
    
        <b-row>
            <b-col cols="12" md="7">
                <b-card no-body>
                    <div class="m-2 p-1 border-viva text-black">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <h2><feather-icon icon="ArrowLeftIcon" /> รายละเอียดใบเสร็จ - {{  receipt.receipt_no }} <span v-if="receipt.status_id == 5" style="color:#f00;">ยกเลิก</span></h2>
                            </div>
                            <div>
                                <h2>
                                    <span v-if="receipt.opd_code">({{ receipt.opd_code }})</span>
                                    {{ receipt.first_name }} {{ receipt.last_name }}
                                    <feather-icon icon="AlertOctagonIcon" />
                                </h2>
                            </div>

                        </div>
                        
                                <b-table
                        ref="refitemsListTable"
                   
                            responsive
                            :fields="tableColumns"
                        
                            :items="receipt.items"
                            show-empty
                            empty-text="No matching records found"
                        
                            class="mt-2 position-relative"
                        >
                        <template #cell(no)="data">
                            
                            {{  data.index+1 }}
                        </template>
                        <template #cell(price)="data">
                            <span v-if="data.item.types=='voucher'">{{  getCommaDecimal(data.item.credits+'.00') }}</span>
                            <span v-else>{{  getCommaDecimal(data.item.price) }}</span>
                            
                          
                        </template>
                        <template #cell(total)="data">
                    
                            {{  getCommaDecimal((data.item.total-data.item.voucher)+'.00') }}
                           
                            <span v-if="data.item.voucher > 0 && (data.item.vcat_id == 8 || data.item.vcat_id == 3)">VC Free</span>
                                <span v-else-if="data.item.voucher > 0 ">Voucher </span>
                                <span v-if="data.item.cancel > 0" style="color:#f00;">คืนสินค้า</span>
                        </template>
                        </b-table>
                    </div>
                </b-card>
               

            </b-col>
            <b-col cols="12" md="5">
                <b-card no-body>
                    <div class="m-2 p-1 border-viva">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <h3>{{ formatDateTh(receipt.due_date) }}</h3>
                            </div>
                            <div>
                                <b-button  
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                class="ml-1"
                                :to="{ name: 'receipt-edit', params:{id:receipt.id}}"
                                v-if="receipt.status_id != 4 && receipt.status_id != 5"
                                variant="outline-info">
                                
                                แก้ไขใบเสร็จ
                            </b-button>
                            <b-button  
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                class="ml-1"
                                :to="{ name: 'receipt-edit', params:{id:receipt.id}}"
                                v-else
                                disabled
                                variant="outline-info">
                                
                                แก้ไขใบเสร็จ
                            </b-button>
                            </div>

                        </div>
                        <div class="d-flex align-items-center justify-content-between mt-3">
                            <div>ราคารวม</div>
                            <div>{{  getCommaDecimal((receipt.total_items-total_cancle)) }}</div>
                            
                        </div>
                        <hr />
                        <div class="d-flex align-items-center justify-content-between">
                            <div>ส่วนลด</div>
                            <div>{{ receipt.discount?getCommaDecimal(receipt.discount):'0.00' }}</div>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center justify-content-between">
                            <div>Voucher</div>
                            <div>{{ receipt.voucher_value?getCommaDecimal(receipt.voucher_value):'0.00' }}</div>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center justify-content-between">
                            <div>มูลค่าหลังส่วนลด</div>
                            <div>{{  getCommaDecimal((receipt.total_items-receipt.discount-total_cancle)) }}</div>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center justify-content-between">
                            <div>ประเภทภาษี</div>
                            <div>
                                <span v-if="receipt.tax_type === 0">ไม่มีภาษี</span>
                                <span v-else>ภาษี(7%)</span>
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center justify-content-between">
                            <div>ภาษีมูลค่าเพิ่ม</div>
                            <div>
                                {{  getCommaDecimal(receipt.tax_value) }}
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center justify-content-between">
                            <div>มูลค่ารวม</div>
                            <div>
                                {{  getCommaDecimal((receipt.total_items-total_cancle-receipt.discount+receipt.tax_value*1)) }}
                            </div>
                        </div>
                        <hr />
                        <b-row class="mt-3">
                            <b-col cols="12" md="6">
                                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="danger" @click="isShowCacelPopup=true"  v-if="receipt.status_id != 5" block>ยกเลิก</b-button>
                                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="secondary"   v-else block>ยกเลิก</b-button>
                            </b-col>
                            <b-col cols="12" md="6">
                                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="info"  v-if="receipt.status_id != 1 " block @click="confirmPrint">พิมพ์ใบเสร็จ</b-button>
                                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="info"  v-else block  disabled>พิมพ์ใบเสร็จ</b-button>
                            </b-col>
                        </b-row>
                        <div class="d-flex align-items-center justify-content-between mt-3">
                            
                            
                            
                        </div>
                        
                    </div>
                </b-card>

            </b-col>
        </b-row>
     <vue-html2pdf
     :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="false"
        filename="receipt.js"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a5"
        pdf-orientation="portrait"
        :paginate-elements-by-height="2480"
        :pdf-content-width="1754"
        
        @beforeDownload="beforeDownload($event)"
 
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
    >
        <section slot="pdf-content" class="pdf-content text-black">
            <div class="m-2 text-center">
                 <b-img
                    src="@/assets/images/logo/viva-logo.png"
                    alt="logo"
                     style="height:75px;"
                     class="mb-1"
                    />
                <p class="text-black" style="font-size:8px;">ปิ่นเกล้า 083.6666.636 : BTS วงเวียนใหญ่ 084.8888.212</p>
                <h3 class=" text-black">วีว่าคลินิกเวชกรรม</h3>
                <h5 class=" text-black">ใบเสร็จรับเงิน</h5>
                <hr />
                <div class="d-flex d-flex justify-content-between text-black">
                    <div >ชื่อ คุณ{{ receipt.first_name }} {{ receipt.last_name }}</div>
                    <div>วันที่ {{ formatDateTh(receipt.due_date) }}</div>
                </div>
                <div class="d-flex d-flex justify-content-between text-black mt-1">
                    <!--<div >OPD CARD NO <span class="pl-2">{{ receipt.opd_code }}</span></div>-->
                    <div>{{ receipt.code_member }} </div>
                    <div>{{  receipt.group_title  }}</div>
                    <div>เลขที่ใบเสร็จ {{  receipt.receipt_no }}</div>
                </div>
            </div>
            <section class="pdf-item m-2 text-center " >
               
               
                <table width="100%" style="font-size:10px;" class="text-black" cellpadding="5px" cellspacing="5px">
                    <thead style="margin:0px; padding:0px; border-top:1px solid #000; border-bottom:1px solid #000;">
                        <tr style="margin:0px; padding:0px;">
                            <th class="text-left" style="margin:0px; padding:0px;">รายการ</th>
                            <th class="text-left" style="margin:0px; padding:0px;">ราคา</th>
                            <th class="text-center" style="margin:0px; padding:0px;">จำนวน</th>
                            <th class="text-center" style="margin:0px; padding:0px;">%ส่วนลด</th>
                            <th class="text-right" style="margin:0px; padding:0px;">รวม</th>
                            <th class="text-right" style="margin:0px; padding:0px;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in receipt.items" :key="'item-'+item.id">
                            <td class="text-left">{{ item.item_name }}</td>
                            <td class="text-left"> 
                                <span v-if="item.types=='voucher'">{{  getCommaDecimal(item.credits+'.00') }}</span>
                                <span v-else>{{  getCommaDecimal(item.price) }}</span>
                                

                            </td>
                            <td class="text-center">{{ item.qty }}</td>
                            <td class="text-center"> {{ getCommaDecimal(item.discount.toString()+'.00') }} </td>
                            <td class="text-right">{{  getCommaDecimal((item.total-item.voucher)+'.00') }}</td>
                            <td>
                                <span v-if="item.voucher > 0 && (item.vcat_id == 8 || item.vcat_id == 3)">VC Free</span>
                                <span v-else-if="item.voucher > 0 ">Voucher</span>
                                <span v-if="item.cancel > 0" style="color:#f00;">คืนสินค้า</span>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot style="margin:0px; padding:0px; border-top:1px solid #000; border-bottom:1px solid #000;">
                        <tr style="margin:0px; padding:0px;">
                            <th class="text-left" style="margin:0px; padding:0px;"></th>
                            <th class="text-left" style="margin:0px; padding:0px;"></th>
                            <th class="text-left" style="margin:0px; padding:0px;">รวม</th>
                         
                            <th class="text-right"  colspan="2" style="margin:0px; padding:0px;"> {{  getCommaDecimal((receipt.total_items-total_cancle-receipt.discount+(receipt.tax_value*1)).toFixed(2)) }}</th>
                            <th></th>
                        </tr>
                    </tfoot>

                </table>
                <div class="mt-1 text-left" style="font-size:10px;" v-if="isShowVoucher == 1">
                    <template v-if="vouchers.length >  0">
                        <div v-for="vc in vouchers" :key="'vc-'+vc.id" v-if="vc.balance > 0">*** มูลค่า {{ vc.category_name }} คงเหลือ {{ getCommaDecimal(vc.balance.toFixed(2)) }} บาท ***</div>

                    </template>
                    <template v-if="receipt.courses.length >  0">
                        
                        <div v-for="co in receipt.courses" :key="'co-'+co.id" v-if="co.qty > 0">
                            <span v-if="co.product_count > 0">*** Course {{ co.title }} คงเหลือ {{  co.product_count }} unit *** วันหมดอายุ {{ formatDateTh(co.expire_date ) }}</span>
                            <span v-else>*** Course {{ co.title }} คงเหลือ {{  co.qty }} ครั้ง *** วันหมดอายุ {{ formatDateTh(co.expire_date ) }}</span>
                        </div>

                    </template>

                </div>
               
            </section>
            <div class="m-2 d-flex align-items-center justify-content-between">
                            
                <div v-if="receipt.gateways.length > 1" >จ่ายโดย 

                    <span  v-for="payment in receipt.gateways" :key="'payment-'+payment.id" class="pl-2">{{ payment.gateway_name }} {{ getCommaDecimal(payment.price )}}</span>
                   
                </div>
                <div v-else >จ่ายโดย 

                    <span  v-for="payment in receipt.gateways" :key="'payment-'+payment.id" class="pl-2">{{ payment.gateway_name }} </span>

                </div>
                 
                <div>วันที่พิมพ์ {{  formatDateThTime(DateNow) }}</div>
                            
            </div>
           
           
        </section>
     
    </vue-html2pdf>
  
    </div>
</template>
<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,BFormTextarea,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,BFormDatepicker,BFormSpinbutton,BModal,BFormCheckboxGroup,
    BFormRadio
    
} from 'bootstrap-vue'
import store from '@/store';
import { ref,onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import useReceiptForm from './useReceiptConfirm'
import receiptStoreModule from '../receiptStoreModule'



import VueHtml2pdf from 'vue-html2pdf'
import { formatDateTh,formatDateThTime,formatDateToMonthShort,getCommaDecimal } from '@core/utils/filter'
export default {
    components:{
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,BFormTextarea,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,BFormDatepicker,BFormSpinbutton,BModal,BFormRadio,
   VueHtml2pdf,
   BFormCheckboxGroup
   

    },
    directives:{
      //  'b-modal': VBModal,
        Ripple

    },
    data(){
        return {
            formatDateTh,
            formatDateToMonthShort,
            formatDateThTime,
            getCommaDecimal,
            isPrintFormActive:false
        }
    },
    setup() {
        const RECEIPT_STORE_MODULE_NAME = 'app-receipt';
            if (!store.hasModule(RECEIPT_STORE_MODULE_NAME)) store.registerModule(RECEIPT_STORE_MODULE_NAME, receiptStoreModule)
            onUnmounted(() => {
                if (store.hasModule(RECEIPT_STORE_MODULE_NAME)) store.unregisterModule(RECEIPT_STORE_MODULE_NAME)
            });
          
            //app-voucher
        const {
            refitemsListTable,
            tableColumns,
            receipt,
            html2Pdf,
            tablePrintColumns,
            DateNow,
            vouchers,
            isShowVoucher,
            updateReceiptStatus,
            CancelReceiptStatus,
            isShowCacelPopup,
            note,
            items_cancle,
            total_cancle
            
        } = useReceiptForm();

        return {
            refitemsListTable,
            tableColumns,
            receipt,
            html2Pdf,
            tablePrintColumns,
            DateNow,
            vouchers,
            isShowVoucher,
            updateReceiptStatus,
            CancelReceiptStatus,
            isShowCacelPopup,
            note,
            items_cancle,
            total_cancle
            
        }
        
    },
    methods: {
        confirmPrint(){
          //  this.generateReport();
          this.isPrintFormActive = true;
        },
        generateReport () {
            this.isPrintFormActive = false;
            this.updateReceiptStatus();
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress(event){
            console.log('event');
        },
        async beforeDownload ({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                 //  pdf.setPage(i)
                   // pdf.setFontSize(10)
                    //pdf.setTextColor(150)
                   // pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                } 
            }).output('bloburl').then(r => { window.open(r) });
        }
    }
}
</script>
<style lang="scss">
/*@page {
  size: A4;
  margin: 11mm 17mm 17mm 17mm;
}*/
.pdf-item{
    position: relative;
    height: 426px;
   
    
}

</style>
